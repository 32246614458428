import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import FileUploader from "./Pages/FileUploader";
import ChatPage from "./Pages/Chat";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<FileUploader />} />
        {/* <Route path="/" element={<Home />} /> */}
        {/* <Route path="/lease-processing" element={<FileUploader />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
